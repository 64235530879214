// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import PropTypes from 'prop-types'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Img from 'gatsby-image'
import { StaticQuery, graphql } from 'gatsby'

import Division from '@bodhi-project/components/lib/Division'
import '@bodhi-project/antrd/lib/just-futura/3.19.3/row/style/css'
import '@bodhi-project/antrd/lib/just-futura/3.19.3/col/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import './style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    imgI1: file(relativePath: { eq: "interoprability-with-other-tools.png" }) {
      ...max1200
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Interoperable */
const Interoperable = ({ ...props }) => {
  return (
    <StaticQuery
      query={query}
      render={data => (
        <div className="interoperable">
          <div className="small-default-container">
            <div className="tech-container">
              <Division breakpoint={768}>
                <Fragment>
                  <div className="tablet-only">
                    <Img fluid={data.imgI1.childImageSharp.fluid} />
                  </div>
                </Fragment>
                <Fragment>
                  <h2>
                    <span>Built with interoperability in mind</span>
                  </h2>
                  <h3>
                    <span>
                      Connect the tools
                      <br className="desktop-only" />
                      <i className="mobile-only">&nbsp;</i>
                      you already use
                    </span>
                  </h3>
                  <p>
                    <i>
                      go2core.ai offers a powerful bridge between training,
                      assessment, and certification agencies.
                    </i>
                  </p>
                  <p>
                    <span>
                      Seamlessly integrate go2core.ai with the technologies
                      you already use a comprehensive digital ecosystem. Keep
                      using your LMS or third-party tool on the back-end, and
                      deliver a authentic assessment experience to test-takers through
                      go2core.ai. Our platform does more than just integrate,
                      it has the potential to stay seamlessly connected to
                      what's happening in any other platform. The impact is a
                      more powerful digital ecosystem with built-in
                      interoperability between technologies and applications.
                    </span>
                  </p>
                </Fragment>
              </Division>
            </div>
          </div>
        </div>
      )}
    />
  )
}

Interoperable.propTypes = {
  to: PropTypes.string,
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Interoperable
