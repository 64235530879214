// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import StandardPage from '../components/wrappers/StandardPage'
import seoHelper from '../methods/seoHelper'
import Interoperable from '../components/home-page/interoperable'
import Cover from '../components/home-page/cover'
import First from '../components/home-page/first'
//import Collaborations from '../components/home-page/collaborations'
import Opener from '../components/home-page/opener'
import Services from '../components/home-page/services'
import TechnologyForGood from '../components/home-page/technology-for-good'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const pageData = {
  pageTitle: 'Full-featured and interoperable assessment platform',
  nakedPageSlug: '',
  pageAbstract:
    'go2core.ai is a next generation competency-based teaching-learning platform fine-tuned to the needs of upskilling institutions and schools all over the world. The platform comes with comprehensive authoring, case-based authentic assessment delivery, and performance reporting.',
}

const seoData = seoHelper(pageData)

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = props => (
  <StandardPage className="page" seoData={seoData} {...props}>
    <Cover />
    <First />
    <Interoperable />
    <Opener />
    <Services />
    <TechnologyForGood />
  </StandardPage>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
