// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Img from 'gatsby-image'
import { StaticQuery, graphql } from 'gatsby'

import FeatureSet from '@bodhi-project/components/lib/feature-set/gatsbyjs'
import '@bodhi-project/components/lib/feature-set/style.less'

import smallKey from '@bodhi-project/components/lib/methods/smallKey'
import mockAbstract from '@bodhi-project/components/lib/methods/mockAbstract'
import mockTimestamp from '@bodhi-project/components/lib/methods/mockTimestamp'
import mockTag from '@bodhi-project/components/lib/methods/mockTag'

import PenTool from 'react-feather/dist/icons/pen-tool'
import FileText from 'react-feather/dist/icons/file-text'
import Settings from 'react-feather/dist/icons/settings'
import Search from 'react-feather/dist/icons/search'
import BarChart from 'react-feather/dist/icons/bar-chart'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../../Link'
import './style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    img1: file(
      relativePath: { eq: "qti-based-modular-assessment-authoring.png" }
    ) {
      ...max1500
    }
    img2: file(relativePath: { eq: "powerful-administration-features.png" }) {
      ...max1500
    }
    img3: file(relativePath: { eq: "offline-and-online-test-delivery.png" }) {
      ...max1500
    }
    img4: file(
      relativePath: { eq: "performance-and-interoperable-reporting.png" }
    ) {
      ...max1500
    }
    img5: file(
      relativePath: { eq: "intelligent-and-actionable-insights.png" }
    ) {
      ...max1500
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Opener */
const Opener = ({ ...props }) => {
  return (
    <StaticQuery
      query={query}
      render={data => {
        const featureSetData = [
          {
            key: smallKey(),
            routeSlug: '#1',
            title: 'Mature QTI Authoring',
            subTitle: 'Development',
            cover: data.img1.childImageSharp.fluid,
            abstract: mockAbstract(),
            publishedAt: mockTimestamp(),
            hasTags: mockTag(),
            color: '#f5efe1',
            iconComponent: PenTool,
            components: {
              abstract: () => (
                <Fragment>
                  <h3 className="mask-h4">Author simple or advanced tests</h3>
                  <p>
                    go2core.ai supports all QTI interaction types. From
                    multiple-choice to technology enhanced items (TEIs) you can
                    create what you need. You can also import existing QTI items
                    or tests directly into go2core.ai if you don’t want to
                    start from scratch.
                  </p>
                  <ul
                    className="mask-p"
                    style={{
                      marginBottom: 0,
                      listStyle: 'disc',
                      paddingLeft: 20,
                    }}
                  >
                    <li>Intuitive point-and-click editing</li>
                    <li>Create portable standards-based TEIs</li>
                    <li>
                      Author learning standards around pre-defined competencies
                      (Qualification Packs)
                    </li>
                    <li>Author items aligned to learning standards</li>
                  </ul>
                </Fragment>
              ),
            },
          },
          {
            key: smallKey(),
            routeSlug: '#2',
            title: 'Powerful Administration Features',
            subTitle: 'Administration',
            cover: data.img2.childImageSharp.fluid,
            abstract: mockAbstract(),
            publishedAt: mockTimestamp(),
            hasTags: mockTag(),
            color: '#e9ebf0',
            iconComponent: Settings,
            components: {
              abstract: () => (
                <Fragment>
                  <h3 className="mask-h4">Manage test-takers</h3>
                  <p>
                    Enter candidate data directly into go2core.ai, or import
                    it from a CSV file. Then, verify test-takers’ identity with
                    usernames and passwords through go2core.ai, or via third
                    party applications that support the OAuth standard, enabling
                    single-sign-on (SSO).
                  </p>
                  <ul
                    className="mask-p"
                    style={{
                      marginBottom: 0,
                      listStyle: 'disc',
                      paddingLeft: 20,
                    }}
                  >
                    <li>
                      The platform has powerful features to administer an
                      assessment. Test location, schedule, and other critical
                      plans are centrally well managed.
                    </li>
                    <li>
                      Roster of a large batch of test-takers can be ingested.
                    </li>
                    <li>
                      Test takers can register directly, or a facilitating
                      organization can register candidates by uploading a
                      roster, with a unique candidate ID.
                    </li>
                  </ul>
                </Fragment>
              ),
            },
          },
          {
            key: smallKey(),
            routeSlug: '#3',
            title: 'Online & Offline Test Delivery',
            subTitle: 'Delivery',
            cover: data.img3.childImageSharp.fluid,
            abstract: mockAbstract(),
            publishedAt: mockTimestamp(),
            hasTags: mockTag(),
            color: '#f5efe1',
            iconComponent: FileText,
            components: {
              abstract: () => (
                <Fragment>
                  <h3 className="mask-h4">Test delivery</h3>
                  <p>
                    Allow access through any standard web browser, including
                    lock-down browsers. Schedule test delivery in advance for
                    individual or groups of candidates. You can also set timing
                    constraints for test availability, duration of tests, and
                    maximum attempts.
                  </p>
                  <ul
                    className="mask-p"
                    style={{
                      marginBottom: 0,
                      listStyle: 'disc',
                      paddingLeft: 20,
                    }}
                  >
                    <li>
                      Online cognitive assessment: assessment can be conducted
                      in a secured environment.
                    </li>
                    <li>
                      Paper and pencil assessment: Where an online assessment is
                      not possible, the assessment can be printed as a question
                      paper, and the test can be undertaken in the paper &
                      pencil mode.
                    </li>
                  </ul>
                </Fragment>
              ),
            },
          },
          {
            key: smallKey(),
            routeSlug: '#4',
            title: 'Interoperable Performance Reporting',
            subTitle: 'Reporting',
            cover: data.img4.childImageSharp.fluid,
            abstract: mockAbstract(),
            publishedAt: mockTimestamp(),
            hasTags: mockTag(),
            color: '#e9ebf0',
            iconComponent: Search,
            components: {
              abstract: () => (
                <Fragment>
                  <h3 className="mask-h4">Simple or customized reporting</h3>
                  <p>
                    Run reports on individual test-takers or groups. Export raw
                    test results to third-party statistical analysis and
                    reporting tools. You can even report to various stakeholders
                    with custom-designed data sets.
                  </p>
                  <ul
                    className="mask-p"
                    style={{
                      marginBottom: 0,
                      listStyle: 'disc',
                      paddingLeft: 20,
                    }}
                  >
                    <li>
                      Dashboard: Reports can be shared in multiple ways.
                      Permission may be granted to administrators to review and
                      download reports in multiple formats.
                    </li>
                    <li>
                      Interoperability: APIs provide an interoperable way to
                      interact with other systems. Using the unique candidate
                      ID, result can be directly carried over to a certification
                      system.
                    </li>
                  </ul>
                </Fragment>
              ),
            },
          },
          {
            key: smallKey(),
            routeSlug: '#5',
            title: 'Intelligent & Actionable Insights',
            subTitle: 'Analytics',
            cover: data.img5.childImageSharp.fluid,
            abstract: mockAbstract(),
            publishedAt: mockTimestamp(),
            hasTags: mockTag(),
            color: '#f5efe1',
            iconComponent: BarChart,
            components: {
              abstract: () => (
                <Fragment>
                  <h3 className="mask-h4">Discover the best talent</h3>
                  <p>
                    Run reports on individual test-takers or groups. Export raw
                    test results to third-party statistical analysis and
                    reporting tools. You can even report to various stakeholders
                    with custom-designed data sets.
                  </p>
                  <ul
                    className="mask-p"
                    style={{
                      marginBottom: 0,
                      listStyle: 'disc',
                      paddingLeft: 20,
                    }}
                  >
                    <li>
                      Item analytics: Student responses to individual test items
                      (questions) in order to assess the quality of those items
                      and of the test as a whole.
                    </li>
                    <li>
                      Performance analytics: Performance Analytics enables you
                      to track, aggregate, and visualize key performance
                      indicators over time, rather than reporting on a point in
                      time.
                    </li>
                    <li>
                      Possible innovations: Based on big data, deep analytics
                      and AI based innovations are possible.
                    </li>
                  </ul>
                </Fragment>
              ),
            },
          },
        ]

        return (
          <div className="opener small-default-container">
            <h2 className="title">
              <span>Key features</span>
            </h2>
            <p className="abstract">
              <span>
                innovate faster and build a unique digital testing ecosystem –
              </span>
            </p>
            <FeatureSet
              Link={Link}
              Img={Img}
              loading={false}
              data={featureSetData}
              threshold={0.9}
            />
          </div>
        )
      }}
    />
  )
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Opener
