// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import PropTypes from 'prop-types'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Img from 'gatsby-image'
import { StaticQuery, graphql } from 'gatsby'

import Box from '@bodhi-project/components/lib/box'
import '@bodhi-project/components/lib/box/style.less'

import Division from '@bodhi-project/components/lib/Division'
import '@bodhi-project/antrd/lib/just-futura/3.19.3/row/style/css'
import '@bodhi-project/antrd/lib/just-futura/3.19.3/col/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import './style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    imgS1: file(relativePath: { eq: "cloud-based-assessment-platform.png" }) {
      ...max1200
    }
    imgS3: file(relativePath: { eq: "training-and-support.png" }) {
      ...max1200
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** HomeServiceDescriptor */
const HomeServiceDescriptor = ({ ...props }) => {
  return (
    <StaticQuery
      query={query}
      render={data => (
        <div className="services">
          <h2 className="title">
            <span>Services Overview</span>
          </h2>
          <p className="abstract">
            <span>
              Work with a team that is here to make sure your assessment
              programs are running smoothly and scaling with your demand –
            </span>
          </p>
          <Box className="default-container">
            <div className="three-quarters-container service1">
              <Division goldenAlt breakpoint={768}>
                <div className="desktop-only">
                  <Img fluid={data.imgS1.childImageSharp.fluid} />
                </div>
                <Fragment>
                  <h2>
                    <span>1. Managed cloud-based services</span>
                  </h2>
                  <h3>
                    <span>
                      Power the assessment
                      <br className="desktop-only" />
                      <i className="mobile-only">&nbsp;</i>
                      experience securely
                    </span>
                  </h3>
                  <p>
                    <i>
                      Spend your time where it matters – fast, flexible and
                      secure cloud-based platform for secure and reliable
                      assessments.
                    </i>
                  </p>
                  <p>
                    go2core.ai managed cloud services lets you tap into the
                    security, flexibility and ease of a fully managed
                    cloud-based deployment to deliver a fast and reliable
                    digital assessment experience, at any scale. We’ll take care
                    of the platform hosting and IT so you can have more time
                    back to focus on your assessment programs. We work with
                    leading cloud providers like AWS and Linode to provide a
                    highly scalable, secure, and robust environment for both
                    online content development and test delivery.
                  </p>
                </Fragment>
              </Division>
            </div>

            <div className="three-quarters-container service3">
              <Division goldenAlt breakpoint={768}>
                <div className="desktop-only">
                  <Img fluid={data.imgS3.childImageSharp.fluid} />
                </div>
                <Fragment>
                  <h2>
                    <span>2. Training and Support</span>
                  </h2>
                  <h3>
                    <span>
                      Extensive technical
                      <br className="desktop-only" />
                      <i className="mobile-only">&nbsp;</i>
                      experience you can rely on
                    </span>
                  </h3>
                  <p>
                    <i>
                      From production to launch, we’re dedicated to helping
                      ensure your assessment programs are up and running
                      smoothly.
                    </i>
                  </p>
                  <p>
                    We know that technology is an investment, so it’s important
                    that your systems are a gateway to your success–not a
                    barrier. That’s why our team is here to make sure you get
                    the most out of your digital assessment solution. Our
                    training is designed to ensure success for your assessment
                    programs at every stage of your deployment.
                  </p>
                </Fragment>
              </Division>
            </div>
          </Box>
        </div>
      )}
    />
  )
}

HomeServiceDescriptor.propTypes = {
  to: PropTypes.string,
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default HomeServiceDescriptor
