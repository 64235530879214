// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Img from 'gatsby-image'
import { StaticQuery, graphql } from 'gatsby'

// import Button from 'antd/lib/button'
// import '@bodhi-project/antrd/lib/just-futura/3.19.3/button/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import './style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    banner: file(
      relativePath: { eq: "next-generation-assessment-platform.jpg" }
    ) {
      ...max900
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** HeroBox */
const HeroBox = props => {
  return (
    <StaticQuery
      query={query}
      render={data => {
        return (
          <div className="page-cover">
            <div className="abstract">
              <h1>
                <small>Next-generation</small>
                <span>Competency-based</span>
                <span>Teaching-Learning</span>
                <small>Platform</small>
              </h1>
              <h2 className="mask-h3">
                <span>Full-featured and&nbsp;</span>
                <strong>interoperable</strong>
                <br />
                <span>assessment platform</span>
              </h2>
              <p>
                <span>
                  go2core.ai is a next generation competency-based teaching-learning platform fine-tuned to the needs of upskilling institutions and schools all over the world. The platform comes with comprehensive authoring, case-based authentic assessment delivery, and performance reporting.
                </span>
              </p>
            </div>
            <div className="banner">
              <Img
                fluid={data.banner.childImageSharp.fluid}
                title="Next-generation Competency-based Teaching-Learning Platform"
                alt="Next-generation Competency-based Teaching-Learning Platform"
              />
            </div>
          </div>
        )
      }}
    />
  )
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default HeroBox
